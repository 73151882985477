import { useEffect } from 'react'
import { useNostoContext } from '../../../components/global/nosto/NostoContext'

export default function useNostoRecommendations(dynamicPlacement: boolean, placement: string) {
  const {
    nostoLoaded,
    pageType,
    productID,
    category,
    searchTerm,
    loadRecs,
    recommendations,
    recommendationsLoading,
    loadingNostoRecommendations,
  } = useNostoContext()

  useEffect(() => {
    if (
      dynamicPlacement &&
      nostoLoaded &&
      window.nostojs &&
      placement &&
      !recommendationsLoading &&
      !recommendations?.[placement]
    ) {
      window.nostojs((api) => {
        loadingNostoRecommendations(true)
        const session = api.defaultSession().setResponseMode('JSON_ORIGINAL')

        const viewMethod =
          {
            CATEGORY: () => session.viewCategory(category),
            '404': session.viewNotFound,
            CART: session.viewCart,
            SEARCH: () => session.viewSearch(searchTerm),
            LANDING: session.viewOther,
            FRONT: session.viewFrontPage,
            PRODUCT: () => session.viewProduct({ product_id: productID }),
          }[pageType || ''] || session.viewOther

        viewMethod()
          .setPlacements([placement])
          .load()
          .then((response) => {
            //Insert the recommendations into the recommendations object and then load them into context
            const recs = response.recommendations ?? null
            const newRecs = recommendations
            if (recs && newRecs) {
              newRecs[placement] = recs[placement]
              loadRecs(newRecs)
            }
            loadingNostoRecommendations(false)
          })
      })
    }
  }, [
    dynamicPlacement,
    nostoLoaded,
    pageType,
    productID,
    category,
    searchTerm,
    placement,
    recommendations,
    loadRecs,
    recommendationsLoading,
    loadingNostoRecommendations,
  ])
}
